
    /* #wrap { 
      width: 600px; 
      height: 500px; 
      padding-top: 10;
      margin-bottom: 100px;
      margin-top: -10px; 
      overflow: hidden; 
    }
     */
    #frame { width: 800px; height: 1520px; border: 1px solid black; }
    #frame {
        -ms-zoom: 0.65;
        -moz-transform: scale(0.55);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.55);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.55);
        -webkit-transform-origin: 0 0;
    }
