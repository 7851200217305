body {
  margin                 : 0;
  font-family            : 'Montserrat', sans-serif !important;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width : 100%;
  height: 100%;
}

.fomulario {
  font-family: 'Montserrat', sans-serif !important;
}

body,
button,
input,
select,
textarea {
  font-family: 'Montserrat', sans-serif !important;
}

#rfc {
  font-weight: bold !important;
}

#referencia {
  font-weight: bold !important;
}

h4 {
  text-transform: uppercase;
}

/* th {
  text-transform: uppercase;
} */
td {
  text-transform: uppercase;
}

div {
  text-transform: uppercase;
}

label,
h3,
columna-labels {
  font-size: 12px !important;
}

.App {
  overflow-y: scroll;
}

h2#swal2-title {
  font-size: 1.1em !important;
}

.tarjeta-facturacion-electronica {
  display: table !important;
}

.centrar {
  display       : table-cell !important;
  vertical-align: middle !important;
}

.form-inline .form-control {
  line-height: normal;
}

.boton {
  height   : 50px !important;
  font-size: 14pt !important;
  width    : 100% !important;
}