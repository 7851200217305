.App {
  /* text-align: center; */
  height: 100%;
  width : 100%;
}

body {
  background-color: rgb(255, 221, 202);
}

html,
body {
  /* background-image: url("./assets/bg/bg-default.jpg"); */
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDUxRjY0ODgyQTkxMTFFMjk0RkU5NjI5MEVDQTI2QzUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDUxRjY0ODkyQTkxMTFFMjk0RkU5NjI5MEVDQTI2QzUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENTFGNjQ4NjJBOTExMUUyOTRGRTk2MjkwRUNBMjZDNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENTFGNjQ4NzJBOTExMUUyOTRGRTk2MjkwRUNBMjZDNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuT868wAAABESURBVHja7M4xEQAwDAOxuPw5uwi6ZeigB/CntJ2lkmytznwZFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYW1qsrwABYuwNkimqm3gAAAABJRU5ErkJggg==); */
  display          : flex;
  height           : 100%;
  width            : 100%;
  background-repeat: no-repeat;
  background-size  : cover;
}


.imagen-logo {
  /* position: absolute;
  width: 50px;
  top: 50px ;
  left: 50px ;
  max-width: 75% !important; */
  position    : absolute;
  /* width    : 50px; */
  /* max-width: 75% !important; */
  height      : 100%;
  width       : auto;
  max-height  : 100%;
}

.ventana-contenido {
  background-color: #333333;
  color           : white;
  align-items     : center;
  justify-content : center;
  margin          : 0 auto;
  padding         : 2em 0em;
  width           : 90%;
  overflow        : scroll;
  overflow-x      : hidden;
}

.ventana-contenido::before {
  content    : '';
  margin-top : -1.6rem;
  position   : absolute;
  color      : black;
  margin-left: 5rem;
}

body {
  background-size      : contain;
  background           : round;
  background-attachment: fixed !important;
  overflow             : hidden;
}


.logo-corporativo {
  -webkit-box-shadow: 9px 10px 10px 2px rgba(0, 0, 0, 0.35);
  -moz-box-shadow   : 9px 10px 10px 2px rgba(0, 0, 0, 0.35);
  box-shadow        : 9px 10px 10px 2px rgba(0, 0, 0, 0.35);
  border-radius     : 8px;
  max-height        : 90%;
}

input.checkbox {
  top: 3px;
}

.Titlulo-Facturacion {
  color      : gray;
  font-weight: bold;
}

.tarjeta-facturacion-electronica {
  color: white;
}


.ticket {
  max-width         : 100%;
  -webkit-box-shadow: 16px 19px 0px 2px rgba(240, 45, 45, 1);
  -moz-box-shadow   : 16px 19px 0px 2px rgba(240, 45, 45, 1);
  box-shadow        : 16px 19px 0px 2px rgba(240, 45, 45, 1);
}


.ventana-contenedor {
  height         : 90%;
  width          : 100%;
  padding        : 10px;
  margin         : 0px auto;
  font-size      : 12px;
  font-weight    : lighter;
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-direction : column;
}


label.label-class {
  margin-right: 10px;
}

select.form-control {
  max-width: 70%;
}

.boton-button {
  background-image   : url('assets/icons/salmon-button.png');
  background-position: center;
  background-size    : cover;
  border             : none;
  width              : 30px;
  height             : 30px;
  background-color   : transparent;
  margin-left        : 5px;
}

.Row-boton-Expandir {
  width                : 25px;
  height               : 25px;
  border               : none;
  border-radius        : 15px;
  color                : white;
  /* background-image  : linear-gradient(to bottom, #F63D0F, #D0D0D0); */
  padding              : 0px;
  padding-bottom       : 2px;
  font-weight          : bold;
  background-size      : cover;
  border               : none;
  background-size      : 110%;
  background-position-x: -1px;
  background-position-y: -1px;
}

.mas {
  background-image: url('assets/icons/signomas.png');
}

.menos {
  background-image: url('assets/icons/signomenos.png');
}

.tfood {
  background    : transparent;
  text-align    : center;
  vertical-align: middle;
}

.form-control {
  border-radius: 0rem !important;
}

p.card-header-title {
  font-weight   : 100;
  padding-bottom: 0em;
  margin-bottom : 0em;
  text-transform: capitalize !important;
  font-size     : 1.15em;
  padding       : 0.5em;
}

.hr {
  background-color  : gray;
  height            : 2px;
  width             : 78%;
  margin-block-end  : 3px;
  margin-block-start: 1px;
}

.monto {
  float: right;
}

.columns {
  padding: 0em 4em;
}

.card.mb-1.traslados {
  margin-top: -6px;
}

.ventana-input {
  width: 100% !important;
}

div#cp {
  width: 100%;
}

div#colonia {
  width: 100%;
}

.cp>div {
  width: 100%;
}

.f-atras {
  background-image     : url('assets/icons/right-left.png');
  padding              : 0px;
  margin-right         : 20px;
  width                : 17px;
  height               : 17px;
  border               : none;
  border-radius        : 15px;
  padding-bottom       : 2px;
  font-weight          : bold;
  border               : none;
  background-size      : 110%;
  background-position-x: -1px;
  background-position-y: -1px;
}

.f-adelante {
  background-size      : cover;
  background-image     : url('assets/icons/grey-right.png');
  padding              : 1.4px;
  margin-left          : 20px;
  padding              : 0px;
  margin-right         : 20px;
  width                : 17px;
  height               : 17px;
  border               : none;
  border-radius        : 15px;
  padding-bottom       : 2px;
  font-weight          : bold;
  background-size      : 110%;
  background-position-x: -1px;
  background-position-y: -1px;
}


header.card-header.columns {
  background: transparent;
  border    : 0px;
}

.card.mb-1.traslados {
  background-color: #202020;
}

.card.mb-1.totales {
  top: -4px;
}

li {
  list-style-type: none;
}

.detalle-conceptos {
  margin-top: 0;
  margin    : 0rem;
}

.derecha {
  text-align: right;
}

.card.totales {
  top: -18px;
}

.paginacion {
  text-align: center;
}

.botonpagini {
  background-image     : url('assets/icons/last-left.png');
  padding              : 7.4px;
  background-size      : 100%;
  cursor               : pointer;
  background-repeat    : no-repeat;
  margin-left          : 5px;
  margin-right         : 5px;
  background-position-y: 7px;
  ;
}

.botonpagfin {
  background-image     : url('assets/icons/last-right.png');
  padding              : 7.4px;
  background-size      : 100%;
  cursor               : pointer;
  background-repeat    : no-repeat;
  margin-left          : 5px;
  margin-right         : 5px;
  background-position-y: 7px;
}

span.numerito {
  padding: 0.5em;
  cursor : pointer;
}


.fondo-default {
  background-image   : url('./../src//assets/bg/bg-default.png');
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : 100% 100%;
}

.envicorreo {
  background-image   : url('./../src//assets/icons/enviocorreo.png');
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;
  padding            : 12.4px;
  background-size    : 100%;
  cursor             : pointer;
  background-repeat  : no-repeat;
  margin-left        : 5px;
  margin-right       : 5px;
  height             : 26px;
  width              : 26px;
}

.col-md-4.top {
  padding-top: 42px;
}

.log {
  margin-top: -57px;
  position  : fixed;
  bottom    : 42px;
  width     : 50%;
}

/* .alerta-modal {
  margin: auto auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  position: fixed;
} */


.rotado-180 {
  transition       : rotate(180deg) !important;
  -ms-transform    : rotate(180deg);
  -webkit-transform: rotate(180deg);
}





input#rfc::placeholder {
  color: #b8b2ff !important;
}

input#ref::placeholder {
  color: #b8b2ff !important;
}




.ventana-logo {
  display : inline-block;
  width   : 8rem;
  height  : 8rem;
  position: absolute;
  z-index : 9;
}

/* linear-gradient(to right, rgb(24, 162, 209) 0%, rgb(36, 73, 155) 100%) */

.ventana-degradado {
  display   : inline-block;
  height    : 3rem;
  width     : 100%;
  /*calc(100% - 8rem);*/
  background: -moz-linear-gradient(to right, rgb(24, 162, 209) 0%, rgb(36, 73, 155) 100%);
  background: -webkit-linear-gradient(to right, rgb(24, 162, 209) 0%, rgb(36, 73, 155) 100%);
  background: linear-gradient(45deg, rgb(24, 162, 209) 0% 40%, rgb(36, 73, 155) 100%);
}

select.form-control.select-style.select-sin-aparence {
  padding-right: 34px !important;
}

select#usocfdi {
  z-index         : 9;
  background-color: transparent;
}

.fondo {
  background-color: #4d4d4d !important;
  height          : 64%;
  width           : 91%;
  position        : absolute;
  margin-top      : 10px;
  left            : 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width             : 7px;
}

::-webkit-scrollbar-thumb {
  border-radius   : 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow      : 0 0 1px rgba(255, 255, 255, .5);
}

.modal-card {
  max-width: 30rem;
  color    : #000
}

.preview {
  margin-left: auto;
}

.facturar-btn {
  margin-left: 10px;
}

@media (max-width: 400px) {
  .hr {
    background-color  : gray;
    height            : 2px;
    width             : 60%;
    margin-block-end  : 3px;
    margin-block-start: 1px;
  }

  .f-atras {
    margin-right: 10px;
  }

  .f-adelante {
    margin-left: 10px;
  }

  .modal-card {
    max-width: 20rem !important;
  }

  .dos-botones {
    display       : flex !important;
    flex-direction: column !important;
  }

  .preview {
    margin-left: 0px !important;
    display    : none;
  }

  .facturar-btn {
    margin-left: 0px !important;
  }

}