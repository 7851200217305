.loading-contenedor {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    
}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #ffffffa8;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
      animation-direction: reverse;
}
 
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}

.loading-background {
    background-color: rgb(163, 163, 163, 0.75);
    height: 100%;
    width: 100%;
    z-index: 10000;
    display: flex;    
    justify-content: center;
    align-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
}


/*  Segundo loading */

.imagen{
    position: absolute;
    width: 50px;
    top: 50px;
    left: 50px;
  }
  .sbl-puzzle {
    height: 150px;
    width: 150px;
    color: #FFF;
    position: relative;
    display: inline-block; }
    .sbl-puzzle div {
      border-radius: 50%;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 10px solid;
      border-top-color: transparent;
      border-bottom-color: transparent;
      animation: rotateTest1 4s linear infinite;
      box-sizing: border-box; }
    .sbl-puzzle div:nth-child(1) {
      height: 75%;
      width: 75%;
      /* margin: 0px; */
      border-left-color: #14b5dd;
      border-bottom-color: #14b5dd;
      border-right-color: #14b5dd;}
    .sbl-puzzle div:nth-child(2) {
      height: 100%;
      width: 100%;
      animation-delay: 1s; }
  
  @keyframes rotateTest1 {
    50% {
      transform: rotate(360deg); } }